import React, { useEffect, useState } from "react";
import "./newsletterTitle.css";
import { useSelector } from "react-redux";

function NewsletterTitle() {
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const nearbyEvents = useSelector((state) => state.landingReducer.nearbyEvents);
  const [mau, setMau] = useState(0);

  useEffect(() => {
    const currentDate = new Date();
    const dateKey = currentDate.toISOString().split("T")[0].slice(0, 7);
    const nextMonthDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    const nextDateKey = nextMonthDate.toISOString().split("T")[0].slice(0, 7);
    fetch("https://xrtph43jo25eztzwqqwlm33igy0skygy.lambda-url.us-west-2.on.aws/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId: currentUser.user_id ?? "", dateKey: dateKey, nextDateKey: nextDateKey }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setMau(data.mau);
      });
  }, [currentUser]);

  return (
    <div className="newsletterTitle">
      <div className="newsletterGreeting">Hello {currentUser.user_first_name} 👋</div>
      <div className="newsletterText">
        There are
        <span className="newsletterNumber">
          <span className="newsletterContent">
            <span>{nearbyEvents.length}</span>
          </span>
        </span>
        events near you, <br /> attended by
        <span className="newsletterNumber newsletterImg2">
          <span className="newsletterContent">
            <span>{mau}</span>
          </span>
        </span>
        potential connections.
      </div>
    </div>
  );
}

export default NewsletterTitle;
