import React, { useEffect, useState } from "react";
import "./splitWidget.css";
import "../widgetStyling.css";
import { useDispatch, useSelector } from "react-redux";
import { SET_EDIT_WIDGET_CONTENT, SET_EDIT_WIDGET_STEP, TOGGLE_EDIT_WIDGET } from "../../../redux/action/dashboardAction";
import XBlack from "../../../assets/XBlack.png";
import { deleteCostApi, getCostApi } from "../../../redux/api/widgets/costApi";
import { ExpandSplit } from "./splitWidgetExpand";

function SplitWidget({ live, data }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const showEditWidget = useSelector((state) => state.dashboardReducer.showEditWidget);
  const [youOwe, setYouOwe] = useState([]);
  const [youAreOwed, setYouAreOwed] = useState([]);
  const [trail, setTrail] = useState([]);
  const [showExpand, setShowExpand] = useState(false);
  const eventGuestlist = useSelector((state) => state.eventReducer.eventGuestlist);

  if (data === undefined) {
    return null;
  }

  function calculateCosts() {
    getCostApi(data.event_id).then((responseArray) => {
      const response = responseArray.costs;
      const splitGroups = responseArray.groups;
      if (response && response.length > 0) {
        const oweOthersMap = {};
        const owesYouMap = {};
        const youOweList = [];
        const youAreOwedList = [];
        const paymentTrailList = [];

        // Calculate the split cost and add it to own and owed maps
        response.forEach((cost) => {
          let splitSize = eventGuestlist.length;
          let splitUpGroup = eventGuestlist.map((user) => user?.user_id);
          if (splitGroups[cost.cost_id]) {
            splitSize = splitGroups[cost.cost_id].length;
            splitUpGroup = splitGroups[cost.cost_id].map((user) => user?.user_id);
          }
          const splitUpCost = (cost.cost / splitSize).toFixed(2);
          if (cost?.user_id !== currentUser?.user_id && splitUpGroup.includes(currentUser?.user_id)) {
            oweOthersMap[cost?.user_id] = oweOthersMap[cost?.user_id] ? Number(oweOthersMap[cost?.user_id]) + Number(splitUpCost) : splitUpCost;
          } else {
            splitUpGroup.forEach((attendeeId) => {
              owesYouMap[attendeeId] = owesYouMap[attendeeId] ? Number(owesYouMap[attendeeId]) + Number(splitUpCost) : splitUpCost;
            });
          }
          let trailCost = cost;
          trailCost.included_users = splitGroups[cost.cost_id]
            ?.filter((user) => user?.user_id !== cost?.user_id)
            .map((user) => {
              return eventGuestlist.find((guest) => guest?.user_id === user?.user_id).user_first_name;
            });
          paymentTrailList.push(trailCost);
        });

        console.log(oweOthersMap);
        console.log(owesYouMap);

        // Find the difference between the two maps to get the net transfer
        for (const [key, value] of Object.entries(oweOthersMap)) {
          if (owesYouMap[key]) {
            let netOwesOthers = Number(value);
            const youPaidMoreDiff = netOwesOthers - Number(owesYouMap[key]); // If the amount I owe is more than the amount I am owed
            if (youPaidMoreDiff > 0) {
              netOwesOthers = youPaidMoreDiff;
            } else if (owesYouMap[key]) {
              netOwesOthers = 0;
            }

            if (netOwesOthers > 0) {
              const firstName = eventGuestlist.find((guest) => guest?.user_id === key).user_first_name;
              const lastName = eventGuestlist.find((guest) => guest?.user_id === key).user_last_name;
              youOweList.push(`${firstName} ${lastName} - $${netOwesOthers.toFixed(2)}`);
            }
          }
        }

        for (const [key, value] of Object.entries(owesYouMap)) {
          if (key === currentUser?.user_id) {
            continue;
          }

          let netOwesYou = Number(value);
          console.log(value)
          const othersPaidMoreDiff = netOwesYou - Number(oweOthersMap[key]); // If the amount I am owed is more than the amount I owe
          if (othersPaidMoreDiff > 0) {
            netOwesYou = othersPaidMoreDiff;
          } else if (oweOthersMap[key]) {
            netOwesYou = 0;
          }

          console.log(netOwesYou);
          if (netOwesYou > 0) {
            const firstName = eventGuestlist.find((guest) => guest?.user_id === key).user_first_name;
            const lastName = eventGuestlist.find((guest) => guest?.user_id === key).user_last_name;
            youAreOwedList.push(`${firstName} ${lastName} - $${netOwesYou.toFixed(2)}`);
          }
        }

        setYouOwe(youOweList);
        setYouAreOwed(youAreOwedList);
        setTrail(paymentTrailList);
      } else {
        setYouOwe([]);
        setYouAreOwed([]);
        setTrail([]);
      }
    });
  }

  useEffect(() => {
    if (live) {
      if (eventGuestlist.length > 0) {
        calculateCosts();
      }
    }
  }, [eventGuestlist]);

  return (
    <div
      className={`widgetView ${!live && !showEditWidget ? "clickable" : ""}`}
      onClick={() => {
        if (!live && !showEditWidget) {
          dispatch({ type: TOGGLE_EDIT_WIDGET });
          dispatch({
            type: SET_EDIT_WIDGET_CONTENT,
            payload: {
              type: "split",
              title: data.title,
              answers: data.answers,
              size: data.component_size,
              id: data.dashboard_id,
              index: data.component_index,
            },
          });
          dispatch({ type: SET_EDIT_WIDGET_STEP, payload: "split" });
        }
      }}
    >
      <div className="widgetTitle">Cost Split</div>
      <div className="splitWidgetContent">
        <div className="splitWidgetHolder invisiScroll">
          <div className="splitWidgetSection">
            <div className="scheduleDayText">You Owe:</div>
            {youOwe.map((owe) => (
              <div key={owe}>{owe}</div>
            ))}
          </div>
          <div className="splitWidgetSection">
            <div className="scheduleDayText">You Are Owed:</div>
            {youAreOwed.map((owed) => (
              <div key={owed}>{owed}</div>
            ))}
          </div>
          <div className="splitWidgetSection">
            <div className="scheduleDayText">Payment Trail:</div>
            {trail.map((trailItem) => (
              <div className="paymentTrailRow" key={trailItem.cost_id}>
                <div>{`${trailItem.user_first_name} paid $${trailItem.cost} for ${trailItem.item_name}${
                  trailItem.included_users ? ` for ${trailItem.included_users?.join(", ")}` : ""
                }`}</div>
                <img
                  className="trailDelete clickable"
                  src={XBlack}
                  alt="DeleteActivity"
                  onClick={() => {
                    deleteCostApi(trailItem.cost_id).then(() => {
                      console.log("Deleted cost");
                      setTimeout(() => {
                        calculateCosts();
                      }, 1000);
                    });
                  }}
                />
              </div>
            ))}
            <div
              className="trailAddButton clickable"
              onClick={() => {
                setShowExpand(true);
              }}
            >
              Add
            </div>
          </div>
        </div>
      </div>
      {showExpand ? (
        <div
          className="popupBackground"
          onClick={(e) => {
            e.stopPropagation();
            setShowExpand(false);
          }}
        >
          <ExpandSplit
            widgetData={data}
            actionRefresh={(close) => {
              if (close) {
                setShowExpand(false);
                setTimeout(() => {
                  calculateCosts();
                }, 1000);
              }
            }}
          />
        </div>
      ) : null}
    </div>
  );
}

export default SplitWidget;
