import React, { useEffect, useState } from "react";
import "./scheduleWidget.css";
import "../widgetStyling.css";
import { useDispatch, useSelector } from "react-redux";
import { SET_EDIT_WIDGET_CONTENT, SET_EDIT_WIDGET_STEP, TOGGLE_EDIT_WIDGET } from "../../../redux/action/dashboardAction";
import { getEventSchedule } from "../../../redux/lambda/scheduleLambda";
import { useNavigate } from "react-router-dom";

function ScheduleWidget({ live, data }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showEditWidget = useSelector((state) => state.dashboardReducer.showEditWidget);
  const [schedule, setSchedule] = useState({});

  if (data === undefined) {
    return null;
  }

  useEffect(() => {
    if (live) {
      getEventSchedule(data.event_id).then((data) => {
        const itemList = data.schedule?.Items;
        const scheduleObj = {};
        let nextItem = itemList.sort((a, b) => a.activityTime.N - b.activityTime.N).find((a) => new Date(parseInt(a.activityTime.N)) > new Date());
        if (nextItem === undefined) {
          nextItem = itemList.sort((a, b) => b.activityTime.N - a.activityTime.N)[0];
        }

        const activityTime = new Date(parseInt(nextItem?.activityTime.N));
        const day = activityTime.toLocaleDateString("en-US", { weekday: "long" });
        const time = activityTime.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
        });
        const activity = nextItem?.activityName.S;
        if (!scheduleObj[day]) {
          scheduleObj[day] = {};
        }
        const existingTimeItems = scheduleObj[day][time]?.value ?? [];
        scheduleObj[day][time] = {
          timestamp: activityTime,
          value: [...(existingTimeItems || []), { name: activity, id: nextItem?.activityId.S }],
        };
        setSchedule(scheduleObj);
      });
    }
  }, []);

  return (
    <div
      className={"widgetView clickable"}
      onClick={() => {
        if (!live && !showEditWidget) {
          dispatch({ type: TOGGLE_EDIT_WIDGET });
          dispatch({
            type: SET_EDIT_WIDGET_CONTENT,
            payload: {
              type: "schedule",
              title: data.title,
              answers: data.answers,
              size: data.component_size,
              id: data.dashboard_id,
              index: data.component_index,
            },
          });
          dispatch({ type: SET_EDIT_WIDGET_STEP, payload: "schedule" });
        } else if (!showEditWidget) {
          navigate(`/schedule/${data.event_id}`);
        }
      }}
    >
      <div className="widgetTitle">{data.title !== "" ? data.title : "Schedule"}</div>
      <div className="widgetContent">
        {Object.entries(schedule).length <= 0 && (
          <div className="noContributionArea">
            <div className="semibold noContributionText">No Schedule Setup</div>
          </div>
        )}
        {Object.entries(schedule).map((dayObj, index) => {
          return (
            <div className="scheduleDayBlock scheduleDayGap" key={index}>
              <div className="scheduleDayText">{dayObj[0]}</div>
              {Object.entries(dayObj[1]).map((timeObj) => {
                const pastTime = new Date(timeObj[1].timestamp) < new Date();
                return timeObj[1].value.map((activityObj) => {
                  return (
                    <div className="scheduleDayBlock" key={activityObj.id}>
                      <div className={`scheduleTimeText${pastTime ? "" : " scheduleFutureBlue"}`}>{timeObj[0]}</div>
                      <div className={pastTime ? "scheduleActivityBlock" : "scheduleActivityBlockFuture"}>
                        <div>{activityObj.name}</div>
                      </div>
                    </div>
                  );
                });
              })}
            </div>
          );
        })}
        {Object.entries(schedule).length > 0 && (
          <div className="noContributionArea">
            <div className="semibold noContributionText">See full schedule</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ScheduleWidget;
