export const getEventSchedule = async (eventId) => {
  return fetch("https://wgp6lnjlfdbmknmd6otk3nqbni0zkkhv.lambda-url.us-west-2.on.aws/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ eventId: eventId }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
};

export const putEventSchedule = async (event) => {
  return fetch("https://j6byneio42af6gnyhfupvzqjta0tqixj.lambda-url.us-west-2.on.aws/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(event),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
};

export const deleteEventSchedule = async (eventId, activityId) => {
  return fetch("https://xj7mxrxkamnxh4ilnwadx2bl4a0mcxku.lambda-url.us-west-2.on.aws/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ eventId: eventId, activityId: activityId }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
};
