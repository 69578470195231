import React, { useEffect, useRef, useState } from "react";
import { POST_COST } from "../../../redux/action/dashboardAction";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Close from "../../../assets/Close.png";
import ProfilePicture from "../../user/profilePicture/profilePicture";

export function ExpandSplit({ widgetData, actionRefresh }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const [item, setItem] = useState("");
  const [cost, setCost] = useState(0);
  const bringingTextBox = useRef(null);
  const costTextBox = useRef(null);
  const eventGuestlist = useSelector((state) => state.eventReducer.eventGuestlist);
  const [selectedGroup, setSelectedGroup] = useState([]);

  useEffect(() => {
    setSelectedGroup(eventGuestlist);
  }, [eventGuestlist]);

  return (
    <div
      className="splitExpand"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="rowFlexSimple space-between">
        <div className="widgetTitle">Add a Cost</div>
        <img
          className="editWidgetCloseImg"
          src={Close}
          alt="Close"
          onClick={() => {
            actionRefresh(true);
          }}
        />
      </div>
      <div className="expandBringContent">
        <div className={"bringContent width100"}>
          <div className="semibold gray80">What did you get?</div>
          <input
            ref={bringingTextBox}
            className="inputBox requestInputBox"
            placeholder="e.g Monopoly"
            onChange={(e) => {
              setItem(e.target.value);
            }}
          ></input>
          <div className="semibold gray80">How much was it?</div>
          <input
            ref={costTextBox}
            className="inputBox requestInputBox"
            placeholder="e.g 20.09"
            onChange={(e) => {
              // remove the $ sign
              const costValue = e.target.value.replace(/\$/g, "");
              setCost(costValue);
            }}
          ></input>
          <div className="semibold gray80">Who is this split between?</div>
          <div className="splitGroupList invisiScroll">
            {eventGuestlist.map((guest, index) => {
              return (
                <div
                  key={index}
                  className="clickable"
                  onClick={() => {
                    if (selectedGroup.includes(guest)) {
                      setSelectedGroup(selectedGroup.filter((item) => item !== guest));
                    } else {
                      setSelectedGroup([...selectedGroup, guest]);
                    }
                  }}
                >
                  <ProfilePicture className={`guestlistPhoto ${selectedGroup.includes(guest) ? "splitSelected" : ""}`} userId={guest.user_id} />
                  <div className="guestlistText">{guest.user_first_name}</div>
                </div>
              );
            })}
          </div>
          <div className="gray80">
            {selectedGroup.length} Users Selected. The split will be ${(Number(cost) / selectedGroup.length).toFixed(2)}
          </div>
          <div className="rowFlex justify-flex-end">
            <div
              className="addItemButton"
              onClick={() => {
                if (item === "" || cost === 0 || isNaN(cost)) {
                  alert("Please enter an item name and valid cost.");
                  return;
                }
                dispatch({
                  type: POST_COST,
                  payload: {
                    id: uuidv4(),
                    dashboard_id: widgetData.dashboard_id,
                    widget_title: widgetData.title,
                    user_id: currentUser.user_id,
                    event_id: widgetData.event_id,
                    cost: cost,
                    item_name: item,
                    included_users: eventGuestlist.length === selectedGroup.length ? [] : selectedGroup.map((user) => user.user_id),
                  },
                });
                bringingTextBox.current.value = "";
                costTextBox.current.value = "";
                actionRefresh(true);
              }}
            >
              Save Cost
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
