import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./schedule.css";
import { GET_EVENT } from "../../../redux/action/eventAction";
import HorizontalEventCard from "../../../components/cards/horizontalEventCard/horizontalEventCard";
import { deleteEventSchedule, getEventSchedule, putEventSchedule } from "../../../redux/lambda/scheduleLambda";
import { v4 as uuidv4 } from "uuid";
import XBlack from "../../../assets/XBlack.png";

function EventSchedule() {
  const eventId = window.location.pathname.split("/")[2];
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const event = useSelector((state) => state.eventReducer.currentEvent);
  const [schedule, setSchedule] = useState({});
  const [addActivityModal, setAddActivityModal] = useState(false);
  const [activityName, setActivityName] = useState("");
  const [activityTime, setActivityTime] = useState("");

  function fetchEventSchedule(eventId) {
    getEventSchedule(eventId).then((data) => {
      const itemList = data.schedule?.Items;
      const scheduleObj = {};
      itemList
        .sort((a, b) => a.activityTime.N - b.activityTime.N)
        .forEach((item) => {
          const activityTime = new Date(parseInt(item.activityTime.N));
          const day = activityTime.toLocaleDateString("en-US", { weekday: "long" });
          const time = activityTime.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
          });
          const activity = item.activityName.S;
          if (!scheduleObj[day]) {
            scheduleObj[day] = {};
          }
          const existingTimeItems = scheduleObj[day][time]?.value ?? [];
          scheduleObj[day][time] = {
            timestamp: activityTime,
            value: [...(existingTimeItems || []), { name: activity, id: item.activityId.S }],
          };
        });
      setSchedule(scheduleObj);
    });
  }

  useEffect(() => {
    dispatch({ type: GET_EVENT, payload: eventId });
    fetchEventSchedule(eventId);
  }, [dispatch, eventId]);

  if (event) {
    let eventDateString = new Date(event.event_start_timestamp);
    eventDateString = eventDateString.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return (
      <div className="schedulePage">
        <div className="scheduleGuideText">On {eventDateString}:</div>
        <HorizontalEventCard key={event.event_id} event={event} />
        <div className="scheduleGuideText">The plan is...</div>
        {Object.entries(schedule).map((dayObj, index) => {
          return (
            <div className="scheduleDayBlock scheduleDayGap" key={index}>
              <div className="scheduleDayText">{dayObj[0]}</div>
              {Object.entries(dayObj[1]).map((timeObj) => {
                const pastTime = new Date(timeObj[1].timestamp) < new Date();
                return timeObj[1].value.map((activityObj) => {
                  return (
                    <div className="scheduleDayBlock" key={activityObj.id}>
                      <div className={`scheduleTimeText${pastTime ? "" : " scheduleFutureBlue"}`}>{timeObj[0]}</div>
                      <div className={pastTime ? "scheduleActivityBlock" : "scheduleActivityBlockFuture"}>
                        <div>{activityObj.name}</div>
                        <img
                          className="deleteActivity"
                          src={XBlack}
                          alt="DeleteActivity"
                          onClick={() => {
                            if (currentUser.user_id !== event.event_host) {
                              alert("You do not have permission to delete this activity.");
                              return;
                            }
                            deleteEventSchedule(eventId, activityObj.id).then(() => {
                              fetchEventSchedule(eventId);
                            });
                          }}
                        />
                      </div>
                    </div>
                  );
                });
              })}
            </div>
          );
        })}
        <div
          className="scheduleDayAddBlock"
          onClick={() => {
            setAddActivityModal(true);
          }}
        >
          <div className={"scheduleActivityBlockFuture"}>Add Activity</div>
        </div>
        {addActivityModal && (
          <div className="addActivityModal">
            <input
              className="addActivityInput"
              type="text"
              placeholder="Activity Name"
              defaultValue={activityName}
              onChange={(e) => {
                setActivityName(e.target.value);
              }}
            />
            <input
              className="addActivityTimeInput"
              type="datetime-local"
              defaultValue={activityTime}
              onChange={(e) => {
                setActivityTime(e.target.value);
              }}
            />
            <div className="rowFlexSimple gap12">
              <div
                className="saveActivityButton clickable"
                onClick={() => {
                  const activityTimestamp = new Date(activityTime).getTime();
                  const eventScheduleObj = {
                    eventId: eventId,
                    activityId: uuidv4(),
                    activityName: activityName,
                    activityTime: activityTimestamp,
                  };
                  if (!activityName || !activityTime) {
                    alert("Please fill out all fields.");
                    return;
                  }
                  putEventSchedule(eventScheduleObj).then(() => {
                    setActivityName("");
                    setActivityTime("");
                    setAddActivityModal(false);
                    fetchEventSchedule(eventId);
                  });
                }}
              >
                Save
              </div>
              <div
                className="closeAddActivityButton clickable"
                onClick={() => {
                  setActivityName("");
                  setActivityTime("");
                  setAddActivityModal(false);
                }}
              >
                Close
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return null;
}

export default EventSchedule;
