import React, { useEffect, useState } from "react";
import "./scheduleWidget.css";
import SaveWidgetButton from "../../button/saveWidgetButton/saveWidgetButton";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import RemoveWidgetButton from "../../button/removeWidgetButton/removeWidgetButton";
import CloseWidgetButton from "../../button/closeWidgetButton/closeWidgetButton";
import ScheduleWidget from "./scheduleWidget";

function EditScheduleWidget() {
  const currentEvent = useSelector((state) => state.eventReducer.currentEvent);
  const editWidgetContent = useSelector((state) => state.dashboardReducer.editWidgetContent);
  const [widgetId, setWidgetId] = useState(uuidv4());
  const [question, setQuestion] = useState("");
  const [answers, setAnswers] = useState([
    {
      poll_id: uuidv4(),
      dashboard_id: "",
      question: "",
      votes: 0,
    },
  ]);
  const [size, setSize] = useState("small");

  useEffect(() => {
    if (editWidgetContent.type === "schedule") {
      console.log(editWidgetContent);
      setWidgetId(editWidgetContent.id);
      setQuestion(editWidgetContent.title);
      setAnswers(editWidgetContent.answers);
      setSize(editWidgetContent.size);
    }
  }, [editWidgetContent]);

  return (
    <div
      className="editWidgetModal"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="editWidgetContent">
        <div className="editWidgetTitleRow">
          <div className="editWidgetTitle">Add your schedule</div>
          <CloseWidgetButton />
        </div>
        <div className="editWidgetDesc">Add a widget directing users to the event schedule.</div>
        <div className="sizeSelector">
          <div className={"sizeSample"}>
            <ScheduleWidget
              live={false}
              data={{
                id: widgetId,
                type: "schedule",
                title: question,
                event_id: currentEvent.event_id,
                answers: answers,
                size: size,
                index: editWidgetContent?.index ?? 0,
              }}
            />
          </div>
        </div>
        <div className="saveAction">
          {editWidgetContent?.id ? <RemoveWidgetButton widget={{ dashboard_id: widgetId, event_id: currentEvent.event_id }} /> : null}
          <SaveWidgetButton
            saveContent={{
              id: widgetId,
              type: "schedule",
              title: "",
              event_id: currentEvent.event_id,
              answers: [],
              size: size,
              index: editWidgetContent?.index ?? 0,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default EditScheduleWidget;
