import defaultAxios from "axios";
import { backend } from "../../../Constants";

const axios = defaultAxios.create({
  baseURL: backend,
  headers: { "Content-Type": "application/json" },
});

export const postCostApi = async (payload) => {
  try {
    const token = JSON.parse(localStorage.getItem("currentUser")).token;
    var headers = {
      Authorization: `Bearer ${token}`,
    };
    const data = JSON.stringify({
      id: payload.id,
      dashboard_id: payload.dashboard_id,
      widget_title: payload.widget_title,
      user_id: payload.user_id,
      event_id: payload.event_id,
      cost: payload.cost,
      item_name: payload.item_name,
      included_users: payload.included_users,
    });

    const cost = await axios.post("cost/", data, {
      headers: headers,
    });
    return cost;
  } catch (err) {
    return console.error(err);
  }
};

export const getCostApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    const cost = await axios.get(`cost/${payload}`, {
      // headers: headers,
    });
    return cost.data;
  } catch (err) {
    return console.error(err);
  }
};

export const deleteCostApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    const cost = await axios.delete(`cost/${payload}`, {
      // headers: headers,
    });
    return cost;
  } catch (err) {
    return console.error(err);
  }
};
